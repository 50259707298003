<template>
  <div v-if="editedItem.id">
    <v-dialog
      v-model="showForm"
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      fullscreen
      @keydown.esc="close"
    >
      <v-form ref="form" v-model="valid">
        <v-card flat>
          <v-card-title>
            {{ formTitle }}
            <v-spacer> </v-spacer>

            <v-btn
              color="blue darken-1"
              text
              @click="save"
              :disabled="ch == 1"
              v-if="modify"
            >
              Enregistrer
            </v-btn>

            <v-btn color="blue darken-1" text @click="close">
              {{ ch == 1 ? "Fermer" : "Annuler" }}
            </v-btn>
          </v-card-title>
          <v-card-subtitle v-if="editedItem.id > 0">
            <h4>
              Code :
              {{ editedItem.code }}
              - Créé le {{ datefr(editedItem.date_crea) }} Par
              {{ editedItem.contact }}
            </h4>
          </v-card-subtitle>
          <v-progress-linear
            v-if="progress"
            indeterminate
            :size="30"
            :width="7"
            color="purple"
          ></v-progress-linear>
          <v-divider></v-divider>

          <v-card-text>
            <v-tabs class="my-tabs" v-model="tab1" @change="tab_change">
              <v-tab href="#1" key="1">
                <v-icon left> mdi-account-details </v-icon>Informations
              </v-tab>

              <v-tab href="#3" key="3" v-if="editedItem.id != -1">
                {{ "Credits" }}
                <v-badge bordered color="green">
                  <template v-slot:badge>
                    <span> {{ tiers_credits.length }} </span>
                  </template>
                </v-badge>
              </v-tab>
              <v-tab href="#4" key="4" v-if="editedItem.id != -1">
                {{ "Vente/Achat" }}
                <v-badge bordered color="green">
                  <template v-slot:badge>
                    <span> {{ tiers_dev.length }} </span>
                  </template>
                </v-badge>
              </v-tab>
              <v-tab href="#5" key="5" v-if="editedItem.id != -1">
                <v-icon left> mdi-account-cash-outline </v-icon>
                Transactions Dinar
                <v-badge bordered color="green">
                  <template v-slot:badge>
                    <span> {{ tiers_dz.length }} </span>
                  </template>
                </v-badge>
              </v-tab>
              <v-tab href="#6" key="6" v-if="editedItem.id != -1">
                <v-icon left> mdi-account </v-icon>
                Contacts
                <v-badge bordered color="green">
                  <template v-slot:badge>
                    <span> {{ tierscontacts.length }} </span>
                  </template>
                </v-badge>
              </v-tab>
              <v-spacer></v-spacer>
              <v-tab href="#8" key="8" v-if="editedItem.id != -1">
                Documents attachés
                <v-badge
                  bordered
                  :color="editedItem.nbdocs == 0 ? 'red' : 'blue'"
                  overlap
                >
                  <template v-slot:badge>
                    <span> {{ editedItem.nbdocs }} </span>
                  </template>
                  <v-icon left> mdi-paperclip </v-icon>
                </v-badge>
              </v-tab>
              <v-tabs-items v-model="currentTab">
                <v-tab-item :value="'1'">
                  <v-row>
                    <!-- Empty Row -->
                    <v-col cols="12" sm="12" md="12"> </v-col>

                    <!-- Pays -->
                    <v-col cols="12" sm="6" md="2">
                      <v-autocomplete
                        outlined
                        :items="countrys"
                        item-text="label"
                        item-value="id"
                        dense
                        v-model="editedItem.country_id"
                        label="Pays"
                        :rules="[(v) => !!v || 'Pays obligatoire']"
                        @change="country_change"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-if="tier_type_list.length != 1"
                    >
                      <v-select
                        v-model="editedItem.tier_type_id"
                        :items="tier_type_list"
                        item-text="label"
                        item-value="id"
                        label="Type"
                        dense
                        outlined
                        @change="ch++"
                        :readonly="!modify"
                      >
                      </v-select>
                    </v-col>
                    <!-- Nom Tier -->
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.nom"
                        label="Nom"
                        :rules="[
                          (v) => !!v || 'Nom obligatoire',
                          (v) => lib || 'Nom existe !!',
                        ]"
                        :loading="libv"
                        :readonly="!modify"
                        @input="lib_change++"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="1"
                      md="1"
                      v-if="
                        editedItem.country_id && editedItem.country_id != '13'
                      "
                    >
                      <v-autocomplete
                        outlined
                        :items="currencies"
                        item-text="name"
                        item-value="id"
                        dense
                        v-model="editedItem.currency_id"
                        label="Devise"
                        :rules="[(v) => !!v || 'Devise obligatoire']"
                        :readonly="!modify"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!-- Adresse -->
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.adresse"
                        label="Adresse"
                        :readonly="!modify"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <!-- sit_init -->
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model.number="editedItem.sit_init"
                        label="Situation intiale"
                        type="number"
                        :readonly="!modify"
                        @focus="$event.target.select()"
                        @input="sit_change"
                        hide-spin-buttons
                      ></v-text-field>
                    </v-col>
                    <!-- email -->
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.email"
                        label="E-mail"
                        :rules="[
                          (v) =>
                            !v || /.+@.+\..+/.test(v) || 'E-mail non valide',
                        ]"
                        :readonly="!modify"
                      ></v-text-field>
                    </v-col>
                    <!-- Tel -->
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        outlined
                        v-model="editedItem.tel"
                        :readonly="!modify"
                        dense
                        label="Tel"
                      ></v-text-field>
                    </v-col>

                    <!-- Mobile -->
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        outlined
                        dense
                        v-model="editedItem.mobile"
                        label="Mobile"
                        :readonly="!modify"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-tab-item>
                <v-tab-item :value="'3'">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <listitems
                        :list="tiers_credits"
                        :title="'CREDITS'"
                        :qDelete="Qdelete_credit"
                        :showedit="false"
                        :showstd="false"
                        :headers="credit_headers"
                        @rowselect="CreditSelect"
                        @open="CreditOpen"
                        :master="true"
                        :add="
                          $store.state.auth.includes('02005') ||
                          $store.state.isadmin
                        "
                        :del="
                          $store.state.auth.includes('02005') ||
                          $store.state.isadmin
                        "
                        :Update="
                          $store.state.auth.includes('02005') ||
                          $store.state.isadmin
                        "
                        :del_disable="credit.statut_id > 1"
                        @delete="item_delete"
                        :key="kcredit"
                        :Total="true"
                      >
                      </listitems>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :value="'4'">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-checkbox
                        v-model="show_annule"
                        label="Afficher Annulée"
                        @change="ktrans++"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <listitems
                        :list="tiers_dev"
                        :title="'Vente/Achat'"
                        :qDelete="Qdelete_trans"
                        :showedit="false"
                        :showstd="false"
                        :headers="trans_headers"
                        @rowselect="TransSelect"
                        @open="TransOpen"
                        :master="true"
                        :add="
                          $store.state.auth.includes('02003') ||
                          $store.state.isadmin
                        "
                        :del="
                          $store.state.auth.includes('02003') ||
                          $store.state.isadmin
                        "
                        :Update="
                          $store.state.auth.includes('02003') ||
                          $store.state.isadmin
                        "
                        :del_disable="transaction.statut_id > 1"
                        @delete="item_delete"
                        :key="ktrans"
                        :Total="true"
                      >
                      </listitems>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :value="'5'">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-checkbox
                        v-model="show_annule"
                        label="Afficher Annulée"
                        @change="ktrans++"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <listitems
                        :list="tiers_dz"
                        title="Operations"
                        :qDelete="Qdelete_trans"
                        :showedit="false"
                        :showstd="false"
                        :headers="
                          trans_headers.filter(
                            (elm) => elm.index != 4 && elm.index != 5
                          )
                        "
                        @rowselect="TransSelect"
                        @open="TransOpen"
                        :master="true"
                        :add="false"
                        :del="false"
                        :Update="
                          $store.state.auth.includes('02003') ||
                          $store.state.isadmin
                        "
                        :del_disable="transaction.statut_id > 1"
                        @delete="item_delete"
                        :key="ktrans"
                        :Total="true"
                      >
                      </listitems>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :value="'6'">
                  <listitems
                    :title="'Contacts'"
                    :key="editedItem.id"
                    :headers="contacts_headers"
                    :list="tierscontacts"
                    :showedit="true"
                    :showstd="true"
                    :qCreate="Qcreate_contact"
                    :qUpdate="Qupdate_contact"
                    :qDelete="Qdelete_contact"
                    :Get_suf="'Contact'"
                    :add="modify"
                    :del="modify"
                    :Update="modify"
                  ></listitems>
                </v-tab-item>
                <v-tab-item :value="'8'">
                  <filelist
                    :item="editedItem"
                    :isform="false"
                    :key="fdocl"
                    :editer="modify"
                    :doc_type="0"
                    :auth="auth"
                    @file_added="file_added"
                    @file_deleted="file_deleted"
                  >
                  </filelist>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card-text>
          <v-card-actions>
            <v-row v-if="editedItem.id > 0">
              <v-col cols="12" sm="4" md="4" class="text-left">
                <v-chip
                  color="orange"
                  width="200"
                  label
                  text-color="white"
                  large
                >
                  <h3>
                    {{
                      "Transfere : " +
                      numberWithSpace(editedItem.tot_debit.toFixed(2))
                    }}
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="text-center">
                <v-chip
                  color="green"
                  width="200"
                  label
                  text-color="white"
                  large
                >
                  <h3>
                    {{
                      "Reçu : " +
                      numberWithSpace(editedItem.tot_credit.toFixed(2))
                    }}
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="text-right">
                <v-chip
                  :color="
                    editedItem.solde < 0
                      ? 'orange'
                      : editedItem.solde > 0
                      ? 'green'
                      : 'gray'
                  "
                  width="200"
                  label
                  text-color="white"
                  large
                >
                  <h3>
                    {{
                      "Solde :" + numberWithSpace(editedItem.solde.toFixed(2))
                    }}
                  </h3>
                </v-chip>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <transform
      :item="transaction"
      :showForm="!isTransClosed"
      :currencies="currencies"
      :users="users"
      :modes="modes"
      :contacts="tierscontacts"
      :tier="editedItem"
      :list="tiers_trans"
      :trans_type="'e'"
      @close="isTransClosed = true"
      @refresh="refresh"
      :key="ktf"
      :modify="
        (transaction.statut_id == 1 || transaction.id < 0) &&
        ($store.state.auth.includes('02003') || $store.state.isadmin)
      "
    >
    </transform>
    <creditform
      :item="credit"
      :showForm="!isCreditClosed"
      :users="users"
      :tier="editedItem"
      :list="tiers_credits"
      @close="isCreditClosed = true"
      @refresh="refresh"
      :key="kcf"
      :modify="
        (credit.statut_id == 1 || credit.id < 0) &&
        ($store.state.auth.includes('02005') || $store.state.isadmin)
      "
    >
    </creditform>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>

    <template>
      <confirmdialog ref="confirm" />
    </template>
  </div>
</template>

<script>
Array.prototype.equals = function (array) {
  // if the other array is a falsy value, return
  if (!array) return false;

  // compare lengths - can save a lot of time
  if (this.length != array.length) return false;

  for (var i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i])) return false;
    } else if (this[i] != array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};

import INSERT_TIER from "../graphql/Tier/INSERT_TIER.gql";
import TIER_UPDATE from "../graphql/Tier/TIER_UPDATE.gql";

import TIERS from "../graphql/Tier/TIERS.gql";

import DELETE_DOC from "../graphql/File/DELETE_DOC.gql";
import DELETE_OPR from "../graphql/Trans/DELETE_OPR.gql";
import DELETE_CREDIT from "../graphql/Credits/DELETE_CREDIT.gql";
import ALLTIERS from "../graphql/Tier/ALLTIERS.gql";

import CREATE_CONTACT from "../graphql/Contact/CREATE_CONTACT.gql";
import UPDATE_CONTACT from "../graphql/Contact/UPDATE_CONTACT.gql";
import DELETE_CONTACT from "../graphql/Contact/DELETE_CONTACT.gql";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    filelist: () => import("../components/FileList.vue"),
    transform: () => import("../components/TransForm.vue"),
    creditform: () => import("../components/CreditForm.vue"),
  },
  props: {
    item: Object,
    showForm: Boolean,
    countrys: Array,
    modify: Boolean,
    modes: Array,
    users: Array,
    currencies: Array,
    route: Object,
  },
  data: () => ({
    show_annule: false,
    tab1: 1,
    ktrans: 200,
    kcredit: 444,
    kcf: 555,
    currentTab: 1,
    lib: true,
    libv: false,
    tier_types: [
      { id: "1", label: "Client" },
      { id: "2", label: "Fournisseur" },
      { id: "3", label: "Autre" },
    ],
    drawer: null,
    isTransClosed: true,
    isCreditClosed: true,
    progress: false,
    fdocl: 1000,
    ktf: 2000,
    ch: 0,
    lib_change: 0,
    tabs: null,
    valid: true,
    transaction: {},
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    credit: {},
    credit_headers: [
      {
        text: "Code",
        align: "start",
        value: "no_credit",
        slot: "href",
        selected: true,
        index: 0,
      },
      {
        text: "Operateur",
        align: "start",
        value: "tier_name",
        selected: true,
        index: 1,
      },
      {
        text: "Operation",
        value: "operation",
        slot: "chip",
        color: "color",
        selected: true,
        index: 2,
      },
      {
        text: "Date Credit",
        value: "credit_date",
        selected: true,
        slot: "date",
        index: 3,
      },
      {
        text: "Echéance",
        value: "date_echeance",
        selected: true,
        slot: "date",
        expire: true,
        index: 4,
      },
      {
        text: "Paye Limite",
        value: "date_limite",
        selected: false,
        slot: "date",
        expire: true,
        index: 5,
      },
      {
        text: "Montant",
        value: "montant",
        selected: true,
        slot: "cur",
        align: "end",
        index: 6,
      },

      {
        text: "Agent",
        value: "user_name",
        selected: false,
        index: 7,
      },

      {
        text: "Status",
        value: "status_name",
        slot: "chip",
        color: "status_color",
        selected: true,
        index: 8,
      },
    ],
    trans_headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
        index: 0,
      },
      {
        text: "Code",
        align: "start",
        value: "no_opr",
        slot: "href",
        selected: true,
        index: 1,
      },
      {
        text: "Operation",
        value: "operation",
        slot: "chip",
        color: "color",
        list: ["A", "V"],
        selected: true,
        index: 1,
      },

      {
        text: "Date",
        value: "opr_date",
        selected: true,
        slot: "date",
        expire: true,
        index: 2,
      },
      {
        text: "Montant",
        value: "montant",
        total: "montant",
        selected: true,
        slot: "cur",
        align: "end",
        index: 3,
      },
      {
        text: "Monnaie",
        value: "currency",
        selected: true,
        index: 4,
      },

      {
        text: "Montant DA",
        value: "mont_dz",
        total: "mont_dz",
        selected: true,
        slot: "cur",
        align: "end",
        index: 5,
      },
      {
        text: "Agent",
        value: "user_name",
        selected: true,
        index: 6,
      },
      {
        text: "Mode",
        value: "type_paye",
        selected: false,
        index: 7,
      },
      {
        text: "Status",
        value: "status_name",
        slot: "chip",
        color: "status_color",
        selected: true,
        index: 8,
      },
    ],
    contacts_headers: [
      {
        text: "tier_id",
        value: "tier_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
      {
        text: "Nom",
        value: "nom",
        type: "text",
        sm: "6",
        md: "12",
        rules: [(v) => !!v || "Nom obligatoire"],
        slot: "href",
        edit: true,
        selected: true,
      },
      {
        text: "Fonction",
        value: "fonction",
        selected: true,
        type: "text",
        sm: "6",
        md: "8",
        edit: true,
      },
      {
        text: "Telephone",
        value: "tel",
        selected: true,
        type: "text",
        sm: "6",
        md: "4",
        edit: true,
      },
      {
        text: "Adresse",
        value: "adresse",
        selected: true,
        type: "text",
        sm: "6",
        md: "12",
        edit: true,
      },
      {
        text: "Email-Pro",
        value: "email_pro",
        rules: [(v) => !v || /.+@.+\..+/.test(v) || "E-mail non valide"],
        selected: true,
        type: "text",
        sm: "6",
        md: "6",
        edit: true,
      },
      {
        text: "Email-Perso",
        value: "email_perso",
        rules: [(v) => !v || /.+@.+\..+/.test(v) || "E-mail non valide"],
        selected: true,
        type: "text",
        sm: "6",
        md: "6",
        edit: true,
      },
      {
        text: "mobile 1",
        value: "mobile1",
        selected: true,
        type: "text",
        sm: "6",
        md: "6",
        edit: true,
      },
      {
        text: "mobile 2",
        value: "mobile2",
        selected: true,
        type: "text",
        sm: "6",
        md: "6",
        edit: true,
      },
    ],
    tierscontacts: [],
    defaultItem: {
      id: -1,
      modalite_id: 1,
      tier_type_id: "1",
      statut_id: "1",
      date_crea: null,
      docs: [],
      country_id: "13",
      currency_id: "114",
      tot_debit: 0,
      tot_credit: 0,
      solde: 0,
    },
  }),

  computed: {
    auth() {
      let a = this.editedItem.tier_type_id == 2 ? "01029" : "01030";
      return a;
    },
    tier_type_list() {
      return this.tier_types;
    },
    tiers_trans() {
      return this.editedItem.id > 0 ? this.editedItem.transactions : [];
    },
    tiers_credits() {
      return this.editedItem.id > 0 ? this.editedItem.credits : [];
    },
    Qcreate_contact() {
      return CREATE_CONTACT;
    },
    Qupdate_contact() {
      return UPDATE_CONTACT;
    },
    Qdelete_contact() {
      return DELETE_CONTACT;
    },
    tiers_dev() {
      let l = [];
      l = this.tiers_trans
        ? this.tiers_trans.filter(
            (elm) =>
              elm.currency_id != "114" &&
              (this.show_annule ? true : elm.statut_id < 3)
          )
        : [];

      return l;
    },
    tiers_dz() {
      let l = [];

      l = this.tiers_trans
        ? this.tiers_trans.filter(
            (elm) =>
              elm.currency_id == "114" &&
              (this.show_annule ? true : elm.statut_id < 3)
          )
        : [];
      return l;
    },
    Qdelete_doc() {
      return DELETE_DOC;
    },
    Qdelete_trans() {
      return DELETE_OPR;
    },
    Qdelete_credit() {
      return DELETE_CREDIT;
    },
    operations: function () {
      return this.editedItem.id > 0 ? this.editedItem.operations : [];
    },

    formTitle() {
      let t =
        this.editedItem.tier_type_id == 1
          ? "Client : "
          : this.editedItem.tier_type_id == 2
          ? "Fournisseur : "
          : " Autre : ";
      return this.editedItem.id < 0 ? "Nouveau Tier" : t + this.editedItem.nom;
    },
  },
  watch: {
    editedItem: {
      handler() {
        this.ch++;
      },
      deep: true,
    },
  },
  created() {
    this.defaultItem.date_crea = this.$store.state.today;
  },
  mounted() {
    if (this.item.id) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.contacts_headers[0].default = this.item.id;
        this.tierscontacts = this.item.contacts;
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
        if (this.tier_type_list.length == 1) {
          this.editedItem.tier_type_id = this.tier_type_list[0].id;
        }
      }
    }
  },

  methods: {
    file_added(n) {
      this.editedItem.nbdocs = parseInt(this.editedItem.nbdocs) + n;
    },
    file_deleted(n) {
      this.editedItem.nbdocs = parseInt(this.editedItem.nbdocs) - n;
    },
    async refresh() {
      let r = await this.requette(TIERS, {
        where: { column: "ID", value: this.editedItem.id },
      });
      if (r) {
        this.editedItem = r.tiers.data[0];
        this.ktrans++;
        this.kcredit++;
        this.$nextTick(() => {
          this.ch = 1;
        });
      }
    },
    sit_change() {
      this.editedItem.solde =
        parseFloat(this.editedItem.tot_credit) -
        parseFloat(this.editedItem.tot_debit) +
        parseFloat(this.editedItem.sit_init ? this.editedItem.sit_init : 0);
    },
    country_change() {
      if (this.editedItem.country_id == "13")
        this.editedItem.currency_id = "114";
      else this.editedItem.currency_id = null;
    },

    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async lib_valid() {
      this.lib = true;

      this.$refs.form.resetValidation();
      if (this.editedItem.nom) {
        this.libv = true;
        let w = null;
        if (this.editedItem.id > 0)
          w = {
            AND: [
              {
                OR: [{ column: "NOM", value: this.editedItem.nom }],
              },

              { column: "ID", operator: "NEQ", value: this.editedItem.id },
            ],
          };
        else
          w = {
            OR: [{ column: "NOM", value: this.editedItem.nom }],
          };
        let v = {
          where: w,
        };
        let r = await this.requette(ALLTIERS, v);
        this.libv = false;
        if (r) {
          if (r.alltiers.length > 0) {
            if (r.alltiers.find((elm) => elm.nom == this.editedItem.nom))
              this.lib = false;
          }
        }
        return this.$refs.form.validate();
      }
    },

    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    tab_change() {
      this.currentTab = this.tab1;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    async close() {
      let ok = true;
      if (this.ch > 1) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en-cours! Etês-vous sûr de vouloir quitter?"
          )
        ) {
          ok = true;
        }
      }

      if (ok) {
        this.$emit("close");
        this.$store.dispatch("Editing", false);
      }
    },
    hist_click() {
      this.drawer = !this.drawer;
      //this.close();
    },
    item_delete() {
      this.$store.dispatch("alert", true);
    },
    CreditSelect(item) {
      this.credit = item;
    },
    CreditOpen(item) {
      this.credit = item;
      this.isCreditClosed = false;
      this.kcf++;
    },
    TransSelect(item) {
      this.transaction = item;
    },
    TransOpen(item) {
      this.transaction = item;
      this.isTransClosed = false;
      this.ktf++;
    },
    cancel() {
      this.$emit("cancel");
      this.editedItem.id = null;
    },
    async save() {
      this.tabs = "1";
      let ok = true;
      if (this.$refs.form.validate()) {
        if (this.lib_change > 0) ok = await this.lib_valid();
        if (ok) {
          if (this.editedItem.id > -1) {
            let v = {
              tier_id: this.editedItem.id,
              tier: [
                {
                  nom: this.editedItem.nom,
                  date_crea: this.editedItem.date_crea,
                  currency_id: this.editedItem.currency_id,
                  tier_type_id: this.editedItem.tier_type_id,
                  sit_init: parseFloat(this.editedItem.sit_init),
                  country_id: this.editedItem.country_id,
                  adresse: this.editedItem.adresse,
                  ville_id: this.editedItem.ville_id,
                  email: this.editedItem.email,
                  tel: this.editedItem.tel,
                  mobile: this.editedItem.mobile,

                  write_uid: this.$store.state.me.id,
                },
              ],
            };
            let r = await this.maj(TIER_UPDATE, v);
            if (r) {
              this.$store.dispatch("Changed", true);
              this.$nextTick(() => {
                this.ch = 1;
              });
            } else ok = false;
          } else {
            let v = {
              tier: [
                {
                  nom: this.editedItem.nom,
                  date_crea: this.editedItem.date_crea,
                  tier_type_id: this.editedItem.tier_type_id,
                  sit_init: parseFloat(this.editedItem.sit_init),
                  adresse: this.editedItem.adresse,
                  ville_id: this.editedItem.ville_id,
                  email: this.editedItem.email,
                  tel: this.editedItem.tel,
                  mobile: this.editedItem.mobile,

                  country_id: this.editedItem.country_id,
                  description: this.editedItem.description,
                  currency_id: this.editedItem.currency_id,

                  create_uid: this.$store.state.me.id,
                  write_uid: this.$store.state.me.id,
                },
              ],
            };
            let r = await this.maj(INSERT_TIER, v);
            if (r) {
              this.editedItem = r.InsertTier;
              this.contacts_headers[0].default = this.editedItem.id;
              this.editedItem.tot_credit = 0;
              this.editedItem.tot_debit = 0;
              this.editedItem.solde = 0;
              this.$nextTick(() => {
                this.ch = 1;
              });

              this.$store.dispatch("Changed", true);
              this.$emit("add", this.editedItem);
            } else {
              ok = false;
            }
          }
          this.$store.dispatch("Editing", false);
        }
      }
    },
  },
};
</script>
<style>
div.my-tabs [role="tab"] {
  justify-content: flex-start;
  margin-right: 2em;
}
</style>
